export default [
  {
    origin: "text",
    name: "text",
    src: require("@/assets/img/widgets/text.svg")
  },
  {
    origin: "image",
    name: "image",
    src: require("@/assets/img/widgets/image.svg")
  },
  {
    origin: "input-field",
    name: "input field",
    src: require("@/assets/img/widgets/contacts.svg")
  },
  {
    origin: "button",
    name: "button",
    src: require("@/assets/img/widgets/button.svg")
  },
  {
    origin: "link",
    name: "link",
    src: require("@/assets/img/widgets/link.svg")
  },
  {
    origin: "video",
    name: "video",
    src: require("@/assets/img/widgets/video.svg")
  },
  {
    origin: "scale",
    name: "scale",
    src: require("@/assets/img/widgets/scale.svg")
  },
  {
    origin: "image-picker",
    name: "image picker",
    src: require("@/assets/img/widgets/image_picker.svg")
  },
  {
    origin: "like-dislike",
    name: "like/islike",
    src: require("@/assets/img/widgets/specify.svg")
  },
  {
    origin: "stars",
    name: "stars",
    src: require("@/assets/img/widgets/stars.svg")
  },
  {
    origin: "download",
    name: "download file",
    src: require("@/assets/img/widgets/download.svg")
  },
  {
    origin: "upload",
    name: "upload file",
    src: require("@/assets/img/widgets/upload.svg")
  }
];
