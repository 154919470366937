<template>
  <div>
    <div class="widgets-container">
      <div
        class="widget-items-container"
        v-for="(w, idx) in widgetsList"
        :key="idx"
      >
        <div class="widgets-item" @click="addWidgetToPreview(w.origin)">
          <img alt="img" :src="w.src" />
          <div>{{ w.name }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { addWidget } from "@/util/survey/widgets";
import widgetsList from "@/store/mocks/widgetsList";
import { mapGetters } from "vuex";

export default {
  name: "PilWidgetsPanel",
  data() {
    return {
      widgetsList
    };
  },
  computed: {
    ...mapGetters({
      device: "workSpace/device",
      survey: "workSpace/survey"
    })
  },
  methods: {
    async addWidgetToPreview(type) {
      if (type.match(/(link|download|upload)/)) {
        this.$store.dispatch("workSpace/showPopup", {
          type: "newWidgetPopup",
          data: { type }
        });
      } else {
        await addWidget(type, this.survey);
        this.survey.fire("canvas:changes");
      }
      if (this.device === "mobile")
        this.$store.commit("workSpace/updateVisibleFeedPanel", false);
    }
  }
};
</script>

<style scoped>
.widgets-container {
  position: relative;
  margin-top: auto;
  width: 100%;
  background: #fff;
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  padding: 10px 0 0;
  overflow: hidden;
}
.widget-items-container {
  width: 25%;
}
.widgets-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-items: center;
  width: fit-content;
  margin: auto;
  height: 37px;
  cursor: pointer;
  margin-bottom: 15px;
  font-size: 8px;
}
.widgets-item img {
  margin: auto;
  max-height: 20px;
}
</style>
